import {
  ButtonGroup,
  Contents,
  DarkBackground,
  DialogBlock,
} from "components/styled/StyledModal";
import React, { useCallback, useState } from "react";

const EMAIL_ADDRESS = "liveall@wjthinkbig.com";

function PopEmail({ handleClose }) {
  const [copied, setCopied] = useState(false);

  const onConfirm = useCallback(
    (e) => {
      e.stopPropagation();
      //   handleClose(e);
      const $text = document.createElement("textarea");
      document.body.appendChild($text);
      $text.value = EMAIL_ADDRESS;
      $text.select();
      document.execCommand("copy");
      document.body.removeChild($text);
      setCopied(true);
    },
    [handleClose]
  );

  const onCancel = useCallback(
    (e) => {
      handleClose(e);
    },
    [handleClose]
  );

  const onSendEmail = useCallback((e) => {
    e.stopPropagation();
    window.location = `mailto:${EMAIL_ADDRESS}`;
  }, []);

  return (
    <DarkBackground onClick={onCancel}>
      <DialogBlock>
        <h3>알림</h3>
        <Contents>
          <p>
            <span>문의는 이메일로 문의하세요.</span>
            <span>
              <br />
              문의 이메일 주소 : <a onClick={onSendEmail}>{EMAIL_ADDRESS}</a>
            </span>
          </p>
        </Contents>
        <ButtonGroup>
          <button onClick={onCancel} className="CancelButton">
            취소
          </button>
          <button onClick={onConfirm} className="ApplyButton">
            이메일 복사
          </button>
        </ButtonGroup>
        {copied && (
          <p className="copyText">
            <span>
              <br />* 복사되었습니다.
            </span>
          </p>
        )}
      </DialogBlock>
    </DarkBackground>
  );
}

export default PopEmail;
