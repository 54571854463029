import React, { useEffect, useMemo, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Scrollbar, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "assets/css/slide/slide.css"; // 카드형 슬라이드 css
import { ClassItem } from "components/indexContent";
import styled from "styled-components";
import "assets/css/detail/detailRecommandClass.css";
import "assets/css/class-item.css";
import { useAppSettings } from "common/hooks";
import useScroll from "./hooks/useScroll";
import { Desktop, TabletAndMobile } from "components/utils/MediaQuery";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ClassCardMap from "components/classCardItem/classCardMap";

// DetailRecommandClass.js
const StyledSwiper = styled(Swiper)`
  position: relative;
`;

function DetailRecommandClass({ type, classes, classStar, children }) {
  SwiperCore.use([Scrollbar, Navigation, Autoplay]); // swiper slide
  const [swiper, setSwiper] = React.useState();
  const { screenTypes } = useAppSettings();

  const scroller = useScroll();

  const recomend = useMemo(() => {
    let list = [];
    if (type === "event") {
      classes.forEach((data, idx) => {
        list.push({
          id: ++idx,
          groupId: data.group_id,
          title: data.title,
          classList: data.class_list,
        });
      });
      return list;
    }
  });

  return (
    <>
      {type === "event" ? (
        <>
          <div
            key={recomend.id}
            className="detailTabBox_Container"
            style={{ padding: "0 !important" }}
          >
            {screenTypes.isTabletAndMobile ? (
              <Tabs
                value={recomend.group_id}
                variant="scrollable"
                scrollButtons="auto"
                className={
                  scroller.ScrollActive
                    ? "detailTabList fixed"
                    : "detailTabList"
                }
                style={{
                  margin: "10px 0",
                  top: scroller.ScrollActive ? "44px" : "",
                  width: "100%",
                  padding: 0,
                  borderBottom: "2px solid #eaeaea",
                }}
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    width: "20px",
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                }}
              >
                {classes &&
                  classes.map((item, i) => (
                    <Tab
                      dataid={i}
                      style={{
                        borderBottom:
                          parseInt(scroller.activeBtn) === i
                            ? "3px solid #ff5651"
                            : "none",
                        font: "1rem/1.33 NotoSansBold",
                        color: "black",
                        textTransform: "none",
                        wordBreak: "keep-all",
                        padding: "10px 5px",
                        letterSpacing: "-0.05em",
                      }}
                      onClick={scroller.tabClickHandler}
                      label={item.group_id}
                      key={i}
                    />
                  ))}
              </Tabs>
            ) : (
              <ul className={"detailTabList"}>
                {classes &&
                  classes.map((item, i) => (
                    <li
                      dataid={i}
                      className={
                        parseInt(scroller.activeBtn) === i ? "isOn" : ""
                      }
                      onClick={scroller.tabClickHandler}
                      style={{ width: `calc(100%/${classes.length})` }}
                    >
                      <Desktop>{item.group_id}</Desktop>
                      <TabletAndMobile>{item.group_id}</TabletAndMobile>
                    </li>
                  ))}
              </ul>
            )}

            <div className="detailTabBox_wrap" ref={scroller.ScrollContent}>
              {recomend &&
                recomend.map((data) => (
                  <div className="detailTabBox isOn">
                    <h1
                      style={{
                        font: screenTypes.isTabletAndMobile
                          ? "1rem/1.33 'NotoSansBold'"
                          : "1.5rem/1.33 'NotoSansBold'",
                      }}
                    >
                      {data.title}
                    </h1>
                    {data.classList.length > 0 && (
                      <ClassCardMap classList={data.classList} type={"class"} />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </>
      ) : (
        // 메인 ?
        <div className="recomendClass_container itemContainer">
          {children}
          <div className="recomendClass_contents_wrap">
            <StyledSwiper
              slidesPerView={screenTypes.isTabletAndMobile ? 1.2 : 4}
              spaceBetween={screenTypes.isDesktop ? 0 : 22}
              // autoplay={{ delay: 2000 }}
              className="slide-card-items-list-type swiper-pagination-type-2 "
            >
              <Swiper
                style={
                  screenTypes.isTabletAndMobile && {
                    width: `calc(100% * ${classes.length})`,
                  }
                }
                className="slide-card-items-list-type"
              >
                {classes &&
                  classes.map((data, index) => (
                    <SwiperSlide key={data.class_seq}>
                      <ClassItem data={data} classStar={classStar} />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </StyledSwiper>
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(DetailRecommandClass);
