import { TabletAndMobile } from "components/utils/MediaQuery";
import Button from "@mui/material/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavLink, useLocation, Link } from "react-router-dom";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { CATEGORY, SUB_CATEGORY } from "../common";
import SubCategory from "./SubCategory";

const Category = ({ category }) => {
  return category.map((item) => (
    <li key={item.main_menu_seq}>
      <Link to={item.link_url} className="btn">
        {item.menu_title}
      </Link>
    </li>
  ));
};

export default function MobileCategory(props) {
  const {
    cate: categorySubed,
    categoryView,
    subCategoryView,
    categorySwitch,
    subCategorySwith,
    swiperParams,
    setSwiper,
    top: mainMenu,
    menu: category,
  } = props;

  const [nowMenu, setNowMenu] = useState("");
  const [nowSub, setNowSub] = useState("");
  const refs = useRef({});
  const tabsWrapRef = useRef(null);

  useLayoutEffect(() => {
    if (categorySubed[0]) setNowSub(categorySubed[0].category_seq);
  }, [categorySubed]);

  useEffect(() => {
    if (refs.current[nowSub])
      refs.current[nowSub].scrollIntoView({ behavior: "smooth" });
  }, [nowSub]);

  const CategorySubed = ({ categorySubed }) => {
    return categorySubed.map((item, idx) => (
      <li key={item.category_name}>
        <button
          className={`btn arr ${item.category_seq === nowSub ? "active" : ""}`}
          onClick={() => {
            setNowSub(item.category_seq);
          }}
        >
          {item.category_name}
        </button>
      </li>
    ));
  };

  const changeSubTab = (item, idx) => {
    tabsWrapRef.current.children[idx].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
    setNowMenu(item);
  };

  // 페이지가 최초 로딩되었을때 tabsWrapRef의 첫번째 자식요소로 스크롤
  useEffect(() => {
    if (mainMenu.length > 0 && tabsWrapRef.current) {
      // 초기 scrollLeft 설정
      tabsWrapRef.current.scrollLeft = 0;

      // setTimeout으로 스크롤 동작 지연
      setTimeout(() => {
        tabsWrapRef.current?.children[0]?.scrollIntoView({
          behavior: "instant",
          inline: "start",
          block: "nearest",
        });
      }, 150);
    }
  }, [mainMenu]);

  return (
    <TabletAndMobile>
      <div className="gnb-area">
        <div className="container">
          <div className="category-box">
            <Button className="category-btn" onClick={categorySwitch} />
            {categoryView && (
              <div className="category-menu-wrap on">
                <ul
                  className={`category-menu ${subCategoryView ? "extend" : ""}`}
                >
                  {categorySubed && (
                    <CategorySubed categorySubed={categorySubed} />
                  )}
                  {category && <Category category={category} />}
                </ul>

                <SubCategory
                  categorySubed={categorySubed}
                  refs={refs}
                  setNowSub={setNowSub}
                />
              </div>
            )}
          </div>

          <div className="gnb mobile-gnb" ref={tabsWrapRef}>
            {mainMenu &&
              mainMenu?.map((item, idx) =>
                item.exposure_yn ? (
                  <span
                    key={`topswifer${idx}`}
                    onClick={() => {
                      changeSubTab(item.menu_title, idx);
                    }}
                    className={`top-menu ${item.menu_title === nowMenu ? "active" : ""}`}
                  >
                    <NavLink to={item.link_url}>{item.menu_title}</NavLink>
                  </span>
                ) : (
                  <></>
                )
              )}
          </div>
        </div>
      </div>
    </TabletAndMobile>
  );
}
