import React from "react";
import ReactDOM from "react-dom";
import "index.css";
import App from "app/App";
import reportWebVitals from "reportWebVitals";
import { BrowserRouter } from "react-router-dom";
// import StoreProvider from 'app/StoreProvider';
import QueryProvider from "app/QueryProvider";
import ReactGA from "react-ga4";
import gtag, { install } from "ga-gtag";
import { GOOGLE_ANALYTICS_TRACKING_ID } from "common/constants";

ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
install(GOOGLE_ANALYTICS_TRACKING_ID);

ReactDOM.render(
  <React.StrictMode>
    <QueryProvider>
      {/* <StoreProvider> */}
      <BrowserRouter>
        <App indexProp="WJ" />
      </BrowserRouter>
      {/* </StoreProvider> */}
    </QueryProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
