import React, { useCallback, useState, useEffect } from "react";
import ClassReview from "./ClassReview";
import ClassInquiry from "./ClassInquiry";
import ClassIntro from "./ClassIntro";
import ClassSupplies from "./ClassSupplies";
import useScroll from "./hooks/useScroll";
import "assets/css/detail/detailTabBox.css";
import { Desktop, TabletAndMobile } from "components/utils/MediaQuery"; //2022-07-20 미디어쿼리 추가

// ScrollTabBox.js

const tabList = [
  "클래스 소개",
  "준비물 & 유의사항",
  "클래스 후기",
  "클래스 문의",
];

function ScrollTabBox({ data }) {
  const scroller = useScroll();

  const [inquiryTotalCount, setInquiryTotalCount] = useState(0);
  const updateInquiryCount = useCallback((count) => {
    setInquiryTotalCount(count);
  }, []);

  return (
    <div className="detailTabBox_Container">
      <ul className={"detailTabList"}>
        {/* //2022-07-20 하단의 각 li 안에 텍스트 모바일/피시 구분 완료 */}
        <li
          dataid={0}
          className={scroller.activeBtn == 0 ? "isOn" : ""}
          onClick={scroller.tabClickHandler}
        >
          클래스
          <TabletAndMobile>
            <br />
          </TabletAndMobile>{" "}
          소개
        </li>
        <li
          dataid={1}
          className={scroller.activeBtn == 1 ? "isOn" : ""}
          onClick={scroller.tabClickHandler}
        >
          준비물 &{" "}
          <TabletAndMobile>
            <br />
          </TabletAndMobile>
          유의사항
        </li>
        <li
          dataid={2}
          className={scroller.activeBtn == 2 ? "isOn" : ""}
          onClick={scroller.tabClickHandler}
        >
          <Desktop>{`클래스 후기(${data.class_review.length})`}</Desktop>
          <TabletAndMobile>
            클래스 후기
            <br /> {`(${data.class_review.length})`}
          </TabletAndMobile>
        </li>
        <li
          dataid={3}
          className={scroller.activeBtn == 3 ? "isOn" : ""}
          onClick={scroller.tabClickHandler}
        >
          <Desktop>{`클래스 문의(${inquiryTotalCount})`}</Desktop>
          <TabletAndMobile>
            클래스 문의
            <br />
            {`(${inquiryTotalCount})`}
          </TabletAndMobile>
        </li>
      </ul>

      <div className="detailTabBox_wrap" ref={scroller.ScrollContent}>
        <ClassIntro data={data.inform_html} />
        <ClassSupplies data={data} />
        <ClassReview classData={data} star={data.class_star} />
        <ClassInquiry
          classData={data}
          updateInquiryCount={updateInquiryCount}
        />
      </div>
    </div>
  );
}

export default React.memo(ScrollTabBox);
