import { DesktopAndTablet, AllMobile } from "../utils/MediaQuery";
import "assets/css/components/banner.scss";

function Banner({ banner }) {
  return (
    <>
      <DesktopAndTablet>
        <div className="event-banner">
          <img src={banner.banner_image_url} alt="피씨배너" />
        </div>
      </DesktopAndTablet>
      <AllMobile>
        <div className="event-banner">
          <img src={banner.banner_mobile_url} alt="모바일배너" />
        </div>
      </AllMobile>
    </>
  );
}
export default Banner;
