import React, { useState } from "react";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box } from "@material-ui/core";
import chat from "assets/images/common/chat-icon.png";
import event from "assets/images/common/event-icon.png";
import eventOn from "assets/images/common/event-icon-on.png";
import { Desktop, TabletAndMobile } from "./MediaQuery";
import { Link } from "react-router-dom";

import { useApiContext } from "common/context";
import { useCommonQuery } from "common/hooks";

import "assets/css/components/btns.scss";

// ScrollTopButton.js

function ScrollTopButton() {
  const { queries } = useApiContext();
  const [visible, setVisible] = useState(false);

  const [floatingBanners, setFloatingBanners] = useState([]);

  const { request: requestFloatingBanner } = useCommonQuery({
    query: queries.mainFloatingBanner,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          console.log(
            "SCrollTopButton.mainFloatingBanner => ",
            data.result_data
          );
          setFloatingBanners(data.result_data);
        } else {
          console.log(data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
      },
    },
  });

  // 2023-06-29 이벤트 플로팅 배너 버튼
  // 2023-08-24 플로팅배너 오픈상태 초기상태 수정
  const [expandedItem, setExpandedItem] = useState(true);
  const handleItemExpand = () => {
    setExpandedItem(!expandedItem);
    console.log(expandedItem);
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div className="footer-navi-icon">
      {floatingBanners?.length > 0 ? (
        floatingBanners.map((item, idx) => (
          <Desktop key={idx}>
            <Box
              className={
                !expandedItem ? "event-icon-wrap" : "event-icon-wrap isOn"
              }
              onClick={() => handleItemExpand(setExpandedItem)}
            >
              {!expandedItem ? (
                <img src={event} />
              ) : (
                <Link
                  to={item?.banner_link_url}
                  style={{ width: "100%", height: "100%" }}
                >
                  <img src={item?.image_url || eventOn} />
                </Link>
              )}
            </Box>
          </Desktop>
        ))
      ) : (
        <></>
      )}
      <Box className="chat-icon-wrap">
        <img src={chat} />
      </Box>
      <div
        onClick={scrollToTop}
        className={visible ? "scrollTopBtn topActive" : "scrollTopBtn"}
      >
        <ArrowUpwardIcon sx={{ color: "#fff" }} />
      </div>
    </div>
  );
}

export default React.memo(ScrollTopButton);
